import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _, { capitalize } from 'lodash'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { Drawer as StaticDrawer } from '@material-ui/core'
import List from '@material-ui/core/List'
import {
  InboxIcon,
  SearchIcon,
  CalendarIcon,
  FavIcon,
  ListIcon,
  BarsIcon,
  MenuIcon,
  PushIcon,
  ClientsIcon,
  SquaresIcon,
  BackButton,
  GuideIcon,
  IconUserManagement,
  IconTag,
  IconKeywordsRpa,
  IconPhasesStatus,
  EvaluativeTest,
  IconEvaluationIa,
  IconJobOffer,
  IconTemplateRequest,
  IconValidatorTemplate,
  GeroBot
} from '../../atoms'
import { DrawerMenuItem, DrawerMenuLogo, DrawerListItem, DrawerCloseButton } from '../../atoms'
import routes from '../../../config/routes'
import { AuthUtils } from '../../../redux/auth'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import PersonIcon from '@material-ui/icons/Person'
import OnboardIcon from '@material-ui/icons/GroupAdd'
import FeedbackIcon from '@material-ui/icons/ContactSupport'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import { ROLES, MODULES, USER_AUTHORIZED_SECTION_KEYS, ALLOWED_EMAILS } from '../../../config/constants'
import colors from '../../../assets/colors'
import * as S from './styles'
import ProfileAvatar from '../../atoms/profile-avatar/view'
import eventTracker from '../../../utils/eventTracker'
import Grid from '@material-ui/core/Grid'
import { FeatureGuard } from '../../../utils/guard'
import useHistoryStack from '../../../hooks/useHistoryStack'
import { useAppContext } from '../../../context/appContext'
import {
  BookUser,
  BriefcaseBusiness,
  CalendarDays,
  CircleCheckBig,
  Star,
  FileVideo2,
  LucidePieChart,
  UserSearch,
  UsersRound,
  Key,
  Integrations,
  Workflow
} from '../../../assets/drawerIcons'

const Drawer = ({ classes, i18n, location, history, client, auth, user_authorized_sections }) => {
  const [state, setState] = React.useState({ open: false })

  const { guards, setGuards } = useAppContext()

  const { pathname } = location

  const [authorizedSections, setAuthorizedSections] = React.useState([])
  useEffect(() => {
    if (user_authorized_sections) {
      let sections = user_authorized_sections.map(item => {
        return item.section
      })
      setAuthorizedSections(sections)
    } else {
      setAuthorizedSections([])
    }
  }, [user_authorized_sections])

  const isRRHHUser = AuthUtils.hasRole(ROLES.ROLE_RRHH_USER)
  const isTechnicalSelection = AuthUtils.hasRole(ROLES.ROLE_TECHNICAL_SELECTION)
  const isOnboard = AuthUtils.hasRole(ROLES.ROLE_ON_BOARDING)
  const isFeedbackUser = AuthUtils.hasRole(ROLES.ROLE_FEEDBACK_USER)
  const isExitInterviewUser = AuthUtils.hasRole(ROLES.ROLE_EXIT_INTERVIEW)
  const isAdminRoot = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_ROOT)

  const toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return
    else setState({ ...state, open })
  }

  const openDrawer = toggleDrawer(true)
  const closeDrawer = toggleDrawer(false)

  //Captación de talento
  const isTalentSupervisor = AuthUtils.hasRole(ROLES.ROLE_SUPERVISOR_talent_acquisition)
  const isTalentAtsAdmin = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_talent_acquisition)
  const isTalentReadOnly = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_talent_acquisition)

  const allowedUserHrbf = ['@hrbotfactory.com']
  const currentUserEmail = auth?.data?.email
  const isUserAllowed = allowedUserHrbf.some(domain => currentUserEmail.endsWith(domain))

  const isUserAllowedPartners = ['edff4c77-f37e-11eb-934d-42010a840128', '9747490c-fd71-11ee-9a85-02d2ff85c53f']
  const isClientAllowedPartners = ['4d0231b2-1f3a-11ef-b4c8-02d2ff85c53f'] // Ferrovial
  const isUserAllowedPartnersBool = isUserAllowedPartners.includes(auth?.data?.id) || isClientAllowedPartners.includes(auth?.client?.id)

  const isOnboardingAtsAdmin = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_on_board)
  const isExitAtsAdmin = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_exit_interview)
  const isFeedbackAtsAdmin = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_feedback)
  const isEmployeeLineAtsAdmin = AuthUtils.hasRole(ROLES.ROLE_ATS_ADMIN_employee_line)

  const isAdminModule = isAdminRoot || isTalentAtsAdmin || isOnboardingAtsAdmin || isExitAtsAdmin || isEmployeeLineAtsAdmin || isFeedbackAtsAdmin

  const currentUserId = auth?.data?.id

  const createDrawerFirstItem = menuItems => {
    localStorage.removeItem(`drawer_first_route`)
    menuItems.map((item, index) => {
      if (index === 0 && !localStorage.getItem(`drawer_first_route`)) {
        localStorage.setItem(`drawer_first_route`, item.route)
      }
    })
  }

  const canAccessToTalentInbox =
    isAdminRoot || isTalentAtsAdmin || isTalentReadOnly || (isTechnicalSelection && authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.INBOX))

  let menuItems = [
    (FeatureGuard.canAccessToJobOffers() || ALLOWED_EMAILS.indexOf(currentUserEmail) > -1) && canAccessToTalentInbox
      ? { route: routes.job_offers, Icon: BriefcaseBusiness, text: i18n.drawer.jobOffers }
      : null,
    isTalentReadOnly || isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.INBOX)
      ? { route: routes.inbox, Icon: IconUserManagement, text: i18n.drawer.inboxPeople, iconClassName: classes.iconInbox, isRoot: true }
      : null,
    FeatureGuard.canAccessToPushOffers() && (isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.PUSH_OFFER))
      ? { route: routes.pushOffer, Icon: PushIcon, text: i18n.drawer.pushOffer }
      : null,
    FeatureGuard.canAccessToPullOffers() && (isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.PULL_CONFIG))
      ? { route: routes.pullConfig, Icon: ClientsIcon, text: i18n.drawer.pullConfig }
      : null,
    isTalentReadOnly || isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.SAVED_SEARCHES)
      ? { route: routes.savedSearches, Icon: SearchIcon, text: i18n.drawer.savedSearches }
      : null,
    isAdminRoot || isTalentAtsAdmin || (authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.PLANNING) && !isTalentReadOnly)
      ? { route: routes.planning, Icon: CalendarDays, text: i18n.drawer.planning, iconClasName: classes.iconCalendar }
      : null,
    authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.RPA_CONFIG)
      ? { route: routes.keywordsRpaTalent, Icon: Key, text: i18n.drawer.keywords_rpa }
      : null,
    isTalentReadOnly || isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.FAVORITES)
      ? { route: routes.favorites, Icon: Star, text: i18n.drawer.favorites }
      : null,
    isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.REPORTS)
      ? { route: routes.reports, Icon: ListIcon, text: i18n.drawer.reports }
      : null,
    isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.STATISTICS_DASHBOARD)
      ? {
        route: routes.dashboardHome.replace(':module_id', MODULES.ID_TALENT_ACQUISITION).replace('/:id?', ''),
        Icon: LucidePieChart,
        text: i18n.drawer.dashboard,
        iconClasName: classes.iconDashboard
      }
      : null,
    FeatureGuard.canAccessToVideoInterview() && (isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.EVALUATIVE_TESTS))
      ? { route: routes.evaluativesTests, Icon: FileVideo2, text: i18n.drawer.evaluativeTests }
      : null,
    FeatureGuard.canAccessToVacancyRequest() &&
      (isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.TEMPLATE_REQUESTOR))
      ? { route: routes.templateRequestManager, Icon: UserSearch, text: i18n.drawer.templateRequestManager, iconClassName: classes.iconInbox, isRoot: true }
      : null,
    FeatureGuard.canAccessToVacancyRequest() &&
      (isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.TEMPLATE_VALIDATOR))
      ? {
        route: routes.validatorTemplateRequest,
        Icon: CircleCheckBig,
        text: i18n.drawer.validatorTemplateRequest,
        iconClassName: classes.iconInbox,
        isRoot: true
      }
      : null,
    // !IMPORTANT: EL MANUAL SIEMPRE ES EL ÚLTIMO DEL MENU
    { route: routes.userManual, Icon: BookUser, text: i18n.drawer.userManual }
  ]
  menuItems = _.compact(menuItems)
  createDrawerFirstItem(menuItems)

  //Procesos
  const isProcessSupervisor = AuthUtils.hasRole(ROLES.ROLE_SUPERVISOR_employee_line)
  let menuItemsEmployee = [
    { route: routes.processes, Icon: InboxIcon, text: i18n.drawer.inbox, iconClasName: classes.iconInbox },
    { route: routes.processesSavedSearches, Icon: SearchIcon, text: i18n.drawer.savedSearches },
    { route: routes.processesFavorites, Icon: FavIcon, text: i18n.drawer.favorites },
    isProcessSupervisor ? { route: routes.processesReports, Icon: ListIcon, text: i18n.drawer.reports } : null,
    isProcessSupervisor ? { route: routes.processesStatistics, Icon: BarsIcon, text: i18n.drawer.statistics, iconClasName: classes.iconStatistics } : null,
    { route: routes.processesUserManual, Icon: GuideIcon, text: i18n.drawer.userManual }
  ]
  menuItemsEmployee = _.compact(menuItemsEmployee)

  //Feedback
  const isFeedbackSupervisor = AuthUtils.hasRole(ROLES.ROLE_SUPERVISOR_feedback)
  let menuItemsFeedback = [
    { route: `/${MODULES.ID_FEEDBACK}/inbox`, Icon: InboxIcon, text: i18n.drawer.inbox, iconClasName: classes.iconInbox },
    { route: `/${MODULES.ID_FEEDBACK}/saved-searches`, Icon: SearchIcon, text: i18n.drawer.savedSearches },
    { route: `/${MODULES.ID_FEEDBACK}/favorites`, Icon: FavIcon, text: i18n.drawer.favorites },
    isFeedbackSupervisor ? { route: `/${MODULES.ID_FEEDBACK}/reports`, Icon: ListIcon, text: i18n.drawer.reports } : null,
    isFeedbackSupervisor
      ? { route: `/${MODULES.ID_FEEDBACK}/statistics`, Icon: BarsIcon, text: i18n.drawer.statistics, iconClasName: classes.iconStatistics }
      : null
  ]
  menuItemsFeedback = _.compact(menuItemsFeedback)

  //Onboarding
  const isOnboardSupervisor = AuthUtils.hasRole(ROLES.ROLE_SUPERVISOR_on_board)
  let menuItemsOnboarding = [
    { route: routes.onboarding, Icon: InboxIcon, text: i18n.drawer.inbox, iconClasName: classes.iconInbox },
    { route: routes.onboardingSavedSearches, Icon: SearchIcon, text: i18n.drawer.savedSearches },
    { route: routes.onboardingFavorites, Icon: FavIcon, text: i18n.drawer.favorites },
    isOnboardSupervisor ? { route: routes.onboardingReports, Icon: ListIcon, text: i18n.drawer.reports } : null,
    isOnboardSupervisor ? { route: routes.onboardingStatistics, Icon: BarsIcon, text: i18n.drawer.statistics } : null,
    { route: routes.onboardingUserManual, Icon: GuideIcon, text: i18n.drawer.userManual }
  ]
  menuItemsOnboarding = _.compact(menuItemsOnboarding)

  //Entrevista de salida
  const isExitInterviewSupervisor = AuthUtils.hasRole(ROLES.ROLE_SUPERVISOR_exit_interview)
  let menuItemsExitInterview = [
    { route: routes.exitInterviewInbox, Icon: InboxIcon, text: i18n.drawer.inbox, iconClassName: classes.iconInbox, isRoot: true },
    { route: routes.exitInterviewFavorites, Icon: FavIcon, text: i18n.drawer.favorites },
    isExitInterviewSupervisor
      ? { route: routes.exitInterviewStatistics, Icon: BarsIcon, text: i18n.drawer.statistics, iconClasName: classes.iconStatistics }
      : null
  ]
  menuItemsExitInterview = _.compact(menuItemsExitInterview)

  let menuItemsMyProfile = [
    { route: routes.myProfile, Icon: InboxIcon, text: i18n.drawer.my_profile, iconClassName: classes.iconInbox, isRoot: true },
    { route: routes.integrations, Icon: Integrations, text: i18n.drawer.integrations, iconClassName: classes.iconInbox, isRoot: true },
    isAdminModule
      ? { route: routes.userManagement, Icon: UsersRound, text: i18n.drawer.user_management, iconClassName: classes.iconInbox, isRoot: true }
      : null,
    isAdminModule
      ? {
        route: routes.tagManagement,
        Icon: () => {
          return IconTag(true)
        },
        text: i18n.drawer.tag_management,
        iconClassName: classes.iconInbox,
        isRoot: true
      }
      : null,

    isAdminRoot || isTalentAtsAdmin
      ? { route: routes.keywordsRpa, Icon: Key, text: i18n.drawer.keywords_rpa, iconClassName: classes.iconInbox, isRoot: true }
      : null,
    isUserAllowedPartnersBool
      ? { route: routes.partners, Icon: ClientsIcon, text: i18n.drawer.partners, iconClassName: classes.iconInbox, isRoot: true }
      : null,
    isAdminRoot || (isTalentAtsAdmin && FeatureGuard.canAccessToFunnelStatus())
      ? { route: routes.phasesStates, Icon: Workflow, text: i18n.drawer.phases_states }
      : null,
    // TODO: Cambiar condición en puesta definitiva en PROD.
    AuthUtils.isDebugUser() && AuthUtils.hasRole(ROLES.ROLE_FEATURE_iagen)
      ? { route: routes.iaEvaluation, Icon: IconEvaluationIa, text: i18n.drawer.iaEvaluation, iconClassName: classes.iconInbox, isRoot: true }
      : null,
    // isAdminRoot || isTalentAtsAdmin ? { route: routes.iaEvaluation, Icon: DashboardIcon, text: i18n.drawer.iaEvaluation, iconClassName: classes.iconInbox, isRoot: true} : null,
    isAdminRoot || isTalentAtsAdmin
      ? {
        route: routes.dashboardWizard.replace(':module_id', 'my-profile'),
        Icon: LucidePieChart,
        text: i18n.drawer.dashboard,
        iconClassName: classes.iconInbox,
        isRoot: true
      }
      : null,
    isAdminModule
      ? { route: routes.superAdministratorManual, Icon: BookUser, text: i18n.drawer.superadministratorManual, iconClassName: classes.iconInbox, isRoot: true }
      : null
  ]

  menuItemsMyProfile = _.compact(menuItemsMyProfile)

  const sections = {
    employee: 'employee',
    recruitment: 'recruitment',
    onboarding: 'onboarding',
    feedback: 'feedback',
    exit_interview: 'exit_interview',
    my_profile: 'my-profile'
  }

  const optionsSections = []
  if (isRRHHUser) optionsSections.push({ value: sections.employee, text: i18n.drawer.employeeLine, icon: PersonIcon })
  if (isOnboard) optionsSections.push({ value: sections.onboarding, text: i18n.drawer.onboarding, icon: OnboardIcon })
  if (isFeedbackUser) optionsSections.push({ value: sections.feedback, text: i18n.drawer.feedback, icon: FeedbackIcon })
  if (isTechnicalSelection) optionsSections.push({ value: sections.recruitment, text: i18n.drawer.talent, icon: SupervisorAccountIcon })
  if (isExitInterviewUser) optionsSections.push({ value: sections.exit_interview, text: i18n.drawer.exit_interview, icon: MeetingRoomIcon })

  const handleListClick = route => {
    let currentSection = route.split('/')
    currentSection = capitalize(currentSection[currentSection.length - 1])
    eventTracker.track(`${currentSection}Section`)

    history.push(route)
    closeDrawer()
  }

  const handleChangeModule = module => {
    closeDrawer()
    switch (module) {
      case sections.employee:
        history.push(routes.processes)
        break
      case sections.onboarding:
        history.push(routes.onboarding)
        break
      case sections.feedback:
        history.push(`/${MODULES.ID_FEEDBACK}/inbox`)
        break
      case sections.exit_interview:
        history.push(`/${MODULES.ID_EXIT_INTERVIEW}/inbox`)
        break
      default:
        history.push(routes.inbox)
    }
  }

  const getSection = () => {
    if (pathname.includes(MODULES.ID_TALENT_ACQUISITION)) return sections.recruitment
    if (pathname.includes(MODULES.ID_EMPLOYEE_LINE)) return sections.employee
    if (pathname.includes(MODULES.ID_ON_BOARDING)) return sections.onboarding
    if (pathname.includes(MODULES.ID_FEEDBACK)) return sections.feedback
    if (pathname.includes(MODULES.ID_EXIT_INTERVIEW)) return sections.exit_interview
    if (pathname.includes(routes.myProfile)) return sections.my_profile
    return null
  }

  const section = getSection()

  const isProfilePage = section === sections.my_profile

  const getMenuItems = section => {
    switch (section) {
      case sections.employee:
        return menuItemsEmployee
      case sections.feedback:
        return menuItemsFeedback
      case sections.recruitment:
        return menuItems
      case sections.onboarding:
        return menuItemsOnboarding
      case sections.exit_interview:
        return menuItemsExitInterview
      case sections.my_profile:
        return menuItemsMyProfile
      default:
        return []
    }
  }

  // Context guards
  useEffect(() => {
    setGuards({
      sections: {
        template_requestor:
          FeatureGuard.canAccessToVacancyRequest() &&
          (isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.TEMPLATE_REQUESTOR)),
        template_validator:
          FeatureGuard.canAccessToVacancyRequest() &&
          (isAdminRoot || isTalentAtsAdmin || authorizedSections.includes(USER_AUTHORIZED_SECTION_KEYS.TEMPLATE_VALIDATOR))
      }
    })
  }, [authorizedSections])

  const historyStack = useHistoryStack()

  if (!optionsSections || optionsSections <= 1) return null
  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        <DrawerMenuLogo isSidebarOpen={state.open} onClick={() => history.push('/')} />
        {section && (
          <>
            {_.map(getMenuItems(section), ({ Icon, route, isRoot }) => (
              <DrawerMenuItem
                key={`DrawerMenuItem_${route}`}
                Icon={Icon}
                selected={pathname.includes(route) || (isRoot && pathname === '/')}
                onClick={() => handleListClick(route)}
              />
            ))}
          </>
        )}

        <S.MenuButton Icon={MenuIcon} onClick={openDrawer} />

        <ProfileAvatar history={history} client={auth.data} />

      </div>
      {section && (!isProfilePage ? (
        <SwipeableDrawer open={state.open} onClose={closeDrawer} onOpen={openDrawer}>
          <div className={classes.list} role="presentation" onKeyDown={closeDrawer}>
            <List className={classes.listContainer}>
              <DrawerMenuLogo isSidebarOpen={state.open} />
              <DrawerCloseButton onClick={closeDrawer} />
              <S.ModeSelector
                value={section}
                onChange={handleChangeModule}
                options={optionsSections.length > 1 ? optionsSections : null}
                color={colors.white}
                text={_.find(optionsSections, { value: section }) ? _.find(optionsSections, { value: section }).text : 'Select module'}
              />
              {_.map(getMenuItems(section), ({ Icon, route, text, iconClassName, isRoot }) => (
                <DrawerListItem
                  key={`DrawerListItem_${route}`}
                  Icon={Icon}
                  selected={pathname === route || (isRoot && pathname === '/')}
                  onClick={() => handleListClick(route)}
                  iconClassName={iconClassName}
                  text={text}
                />
              ))}
            </List>
          </div>
        </SwipeableDrawer>
      ) : <StaticDrawer variant="permanent">
        <div className={classes.staticList} role="presentation" onKeyDown={closeDrawer}>
          <List className={classes.listContainer}>
            <Grid style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
              <DrawerMenuLogo isSidebarOpen={state.open} onClick={() => history.push('/')} />
              <BackButton title={i18n.headerProfileButtons.back} color={"#fff"} onClick={() => historyStack.length > 1 ? history.goBack() : history.push('/')} />
            </Grid>
            {_.map(getMenuItems(section), ({ Icon, route, text, iconClassName, isRoot }) => (
              <DrawerListItem
                key={`DrawerListItem_${route}`}
                Icon={Icon}
                selected={pathname === route || (isRoot && pathname === '/')}
                onClick={() => handleListClick(route)}
                iconClassName={iconClassName}
                text={text}
              />
            ))}
          </List>
        </div>
      </StaticDrawer>)}
      {isTechnicalSelection && <GeroBot />}
    </div>
  )
}

Drawer.propTypes = {
  classes: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  client: PropTypes.object,
  token: PropTypes.string,
  auth: PropTypes.object
}

export default Drawer
